/**
 * Imports
 */

import * as React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { maybeUserSelector } from 'features/user/redux/selectors';
import { styled } from 'theme';

import { PrivateRoute } from './PrivatRoute';
import { AccountPage, LoginPage } from 'features/user/pages';
import { Trans } from 'react-i18next';
import { HomePage } from 'features/navigation/pages';

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.Vibrant.Colors.Background.Primary};

  height: 100%;
  min-height: 100%;
`;

/**
 * Types
 */
export type RoutesType = 'home' | 'account' | 'login';

export type RouteLookup = {
  [k in RoutesType]: RouteType;
};

export type RouteType = {
  pageName: string | React.ReactNode;
  path: string;
  route: string;
  pageId: RoutesType;
};

export interface RootRouterProps {}

/**
 * Routes settings
 */
export const ROUTES: RouteLookup = {
  home: {
    pageName: <Trans i18nKey="Global.pages.home" />,
    pageId: 'home',
    path: '/',
    route: '/',
  },
  account: {
    pageName: <Trans i18nKey="Global.pages.account" />,
    pageId: 'account',
    path: '/account',
    route: '/account',
  },
  login: {
    pageName: <Trans i18nKey="Global.pages.login" />,
    pageId: 'login',
    path: '/login',
    route: '/login',
  },
};

/**
 * RootRouter component
 */
const RootRouter: React.FC<RootRouterProps> = () => {
  /* Redux*/
  const user = useSelector(maybeUserSelector);

  console.log('root user', user);

  return (
    <BrowserRouter>
      {!user && (
        <Root>
          <Switch>
            <Route exact path={ROUTES.home.path}>
              <HomePage />
            </Route>
            <Route exact path={ROUTES.login.path}>
              <LoginPage />
            </Route>
          </Switch>
        </Root>
      )}
      {!!user && (
        <Root>
          <Switch>
            <PrivateRoute exact path={ROUTES.home.path}>
              <HomePage />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.account.path}>
              <AccountPage />
            </PrivateRoute>
            <PrivateRoute exact path={ROUTES.login.path}>
              <LoginPage />
            </PrivateRoute>
          </Switch>
        </Root>
      )}
    </BrowserRouter>
  );
};

/**
 * Exports
 */
export { RootRouter };
