/**
 * Imports
 */
import * as React from 'react';
import { styled } from 'theme';
import { LoginForm } from 'features/user/components';

/**
 * Styles Components
 */
const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex: 1;
  height: 100%;
  margin-bottom: auto;
`;

/**
 * LoginPage component
 */
const LoginPage: React.FC = () => {
  return (
    <Root>
      <LoginForm />
    </Root>
  );
};

/**
 * Exports
 */
export { LoginPage };
