/**
 * Imports
 */
import { createSelector } from '@reduxjs/toolkit';

import { VibrantlyState } from 'store/types';

/**
 * Selectors
 */
export const rootSelector = (state: VibrantlyState) => state.navigation;

export const sidebarCollapsedSelector = createSelector(
  [rootSelector],
  (root) => root.collapsed,
);

export const sidebarVisibleSelector = createSelector(
  [rootSelector],
  (root) => root.visible!,
);
