/**
 * Imports
 */
import Icon from '@ant-design/icons';
import * as React from 'react';

/**
 * Types
 */
export interface LogoProps {
  single?: boolean;
}

/**
 * Logo in SVG
 */
const LogoSvg = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 89 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M79.6288 11.4537V19.6157H76.1611C76.1611 19.6157 76.1611 12.3745 76.1542 12.3018C76.1542 10.5815 75.1674 9.69881 73.6524 9.69881C72.2417 9.69881 70.9422 10.5538 70.9422 12.3018V19.6365H67.4675V6.75662H70.8553V8.2381C71.7622 6.9643 73.2146 6.45547 74.6219 6.45547C75.3272 6.45547 75.9839 6.57316 76.5711 6.79469C78.4266 7.48697 79.6288 9.19691 79.6288 11.4537Z"
      fill="#06302D"
    />
    <path
      d="M23.0821 6.75668H19.6074V19.6573H23.0821V6.75668Z"
      fill="#06302D"
    />
    <path
      d="M15.5457 19.6574H12.071L13.4852 3.54453H16.9599L15.5457 19.6574Z"
      fill="#141414"
    />
    <path
      d="M89 19.5328C88.3642 19.7231 87.5754 19.8997 86.7241 19.9377C86.6303 19.9447 86.5365 19.9481 86.4392 19.9481C86.4114 19.9516 86.3871 19.9516 86.3593 19.9516C82.155 19.9516 81.8005 16.144 81.8005 14.2956V3.54453H85.2752V6.75671H87.8812L87.5789 10.2181H85.2752V14.2956C85.2752 15.9329 86.0153 16.6459 87.0021 16.7325H87.0056C87.4295 16.7671 88.2321 16.684 88.7464 16.6217L88.9097 18.4909L89 19.5328Z"
      fill="#141414"
    />
    <path
      d="M61.4597 6.75666V8.16545C60.6119 7.1651 59.3055 6.40359 57.582 6.40359C54.0205 6.40359 51.3276 9.31118 51.3276 13.1776C51.3276 17.0405 54.0205 19.9515 57.582 19.9515C59.3055 19.9515 60.5355 19.2766 61.3729 18.2831V19.6089H64.8475V6.75666H61.4597ZM61.3729 14.4029C60.9455 15.7875 59.7815 16.722 58.2214 16.722C56.1817 16.722 54.8857 15.1436 54.8857 13.1776C54.8857 11.2115 56.1817 9.62962 58.2214 9.62962C59.7815 9.62962 60.9455 10.5642 61.3729 11.9522C61.491 12.3295 61.5536 12.7414 61.5536 13.1776C61.5536 13.6137 61.491 14.0256 61.3729 14.4029Z"
      fill="#141414"
    />
    <path
      d="M33.3811 6.45206C31.6612 6.45206 30.4416 7.1305 29.5937 8.13084V0.384216H26.1226V19.6573H29.5937V18.3316C30.4311 19.325 31.6612 20 33.3811 20C36.9427 20 39.632 17.089 39.632 13.226C39.632 9.35964 36.9427 6.45206 33.3811 6.45206ZM32.7453 16.7705C31.1851 16.7705 30.0211 15.8359 29.5937 14.4514C29.4756 14.0741 29.4131 13.6622 29.4131 13.226C29.4131 12.7899 29.4756 12.378 29.5937 12.0007C30.0211 10.6127 31.1851 9.67809 32.7453 9.67809C34.7814 9.67809 36.0775 11.26 36.0775 13.226C36.0775 15.1921 34.7814 16.7705 32.7453 16.7705Z"
      fill="#141414"
    />
    <path
      d="M21.3448 4.15369C22.4962 4.15369 23.4296 3.22385 23.4296 2.07684C23.4296 0.929834 22.4962 0 21.3448 0C20.1934 0 19.26 0.929834 19.26 2.07684C19.26 3.22385 20.1934 4.15369 21.3448 4.15369Z"
      fill="#141414"
    />
    <path
      d="M50.7022 6.71857L50.1011 10.1592C46.1435 8.81272 45.2679 11.0349 45.2679 12.821V19.6469H41.7932V6.75665H45.181V8.18275C46.689 5.95706 49.4201 6.37243 50.7022 6.71857Z"
      fill="#141414"
    />
    <path
      d="M0.302296 6.86392L0 10.3149C4.80893 10.7269 8.59631 14.7628 8.59631 19.6573H12.071C12.071 12.9422 6.8972 7.43159 0.302296 6.86392Z"
      fill="#141414"
    />
  </svg>
);

const LogoSingleSvg = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9986 23H17.0815L19.0828 0H23.9998L21.9986 23Z"
      fill="currentColor"
    />
    <path
      d="M0.427781 4.73834L0 9.66444C6.80517 10.2524 12.1647 16.0135 12.1647 23H17.0818C17.0818 13.4146 9.7603 5.54865 0.427781 4.73834Z"
      fill="currentColor"
    />
  </svg>
);

/**
 * Types
 */
export interface LogoIconProps {
  single?: boolean;
  style: any;
}

/**
 * LogoIcon component
 */
const LogoIcon: React.FC<LogoIconProps> = ({ single, style, ...rest }) => {
  return (
    <Icon
      component={single ? LogoSingleSvg : LogoSvg}
      {...rest}
      style={style}
    />
  );
};

/**
 * Exports
 */
export { LogoIcon };
