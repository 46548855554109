/**
 * Imports
 */
import * as React from 'react';
import { styled } from 'theme';
import { Sidebar } from '../Sidebar';

/**
 * Styling
 */
const Body = styled.div`
  display: flex;
  flex: 1;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

/**
 * Types
 */
export interface BaseLayoutProps {}

/**
 * BaseLayout component
 */
const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
  return (
    <Body>
      <Sidebar />
      <Main>{children}</Main>
    </Body>
  );
};

/**
 * Exports
 */
export { BaseLayout };
