/**
 * Imports
 */
import { IconBaseProps } from '@ant-design/icons/lib/components/Icon';
import * as React from 'react';

import { styled } from 'theme';
import { ColorShadesType, VibrantColors } from 'theme/colors';

/**
 * Styling
 */
const Root = styled.div`
  display: flex;
`;

/**
 * Types
 */
export type CustomIconProps = IconBaseProps & {
  icon: React.ReactNode;
  color?: ColorShadesType;
  size?: number;
};

/**
 * Icon component
 */
const Icon: React.FC<CustomIconProps> = ({ color, icon, size }) => {
  return (
    <Root
      style={{
        color: color
          ? VibrantColors.Shades[color]
          : VibrantColors.Shades.Grey900,
        fontSize: size ? `${size}px` : '16px',
      }}
    >
      {icon}
    </Root>
  );
};

/**
 * Exports
 */
export { Icon };
