/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';
import { styled } from 'theme';

/**
 * Styling
 */
const Content = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface HomePageProps {}

/**
 * HomePage component
 */
const HomePage: React.FC<HomePageProps> = (_props) => {
  return (
    <BaseLayout>
      <Content>HomePage</Content>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { HomePage };
