/**
 * Imports
 */
import * as components from './components';
import * as pages from './pages';
import redux from './redux';
/**
 * Exports
 */
export default { components, pages, redux };
