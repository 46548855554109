/**
 * Imports
 */
import { combineReducers } from '@reduxjs/toolkit';

import features from 'features';

/**
 * The app's reducer
 */
const rootReducer = combineReducers({
  navigation: features.navigation.redux.reducer,
  user: features.user.redux.reducer,
});

/**
 * Exports
 */
export { rootReducer };
