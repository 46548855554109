/**
 * Imports
 */
import { BaseLayout } from 'features/navigation/components';
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styling
 */
const Content = styled.div`
  padding: 24px;
`;

/**
 * Types
 */
export interface AccountPageProps {}

/**psideba
 * AccountPage component
 */
const AccountPage: React.FC<AccountPageProps> = (props) => {
  return (
    <BaseLayout>
      <Content>AccountPage</Content>
    </BaseLayout>
  );
};

/**
 * Exports
 */
export { AccountPage };
