/**
 * imports
 */
import i18n from 'i18next';
import en from './en.json';
import da from './da.json';
import { initReactI18next } from 'react-i18next';
/**
 * Exports
 */
export const resources = {
  en,
  da,
} as const;

/**
 * Localization setup
 */
i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
    fallbackLng: 'en',
    resources,
    ns: ['translations'], // have a common namespace used around the full app
    defaultNS: 'translations',
  });

export default i18n;
