/**
 * Imports
 */
import * as React from 'react';

import styled from 'styled-components';
import { PasswordModal } from './PasswordModal';

/**
 * Styles Components
 */
const Root = styled.div`
  margin-top: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const LinkText = styled.a`
  display: flex;
  flex: 1;
  color: white;
`;

/**
 * Types
 */
export interface LostPasswordProps {}

/**
 * LogoutButton component
 */
const LostPassword: React.FC<LostPasswordProps> = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  return (
    <Root>
      <LinkText
        onClick={() => {
          setShowModal(true);
        }}
      >
        Send me a new password
      </LinkText>
      <PasswordModal show={showModal} setShowModal={setShowModal} />
    </Root>
  );
};

/**
 * Exports
 */
export { LostPassword };
