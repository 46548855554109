/**
 * Imports
 */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { maybeUserSelector } from 'features/user/redux/selectors';

/**
 * Types
 */
type PrivateRouteProps = Route['props'];

/**
 * `PrivateRoute` component
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const user = useSelector(maybeUserSelector);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !!user ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );
};

/**
 * Exports
 */
export { PrivateRoute };
