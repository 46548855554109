/**
 * Imports
 */
import * as React from 'react';

import { styled } from 'theme';

/**
 * Styled components
 */
const Flex = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
`;

/**
 * Types
 */
export type FlexDirection = 'row' | 'row-reverse' | 'column-reverse';
export interface LayoutProps {
  direction?: FlexDirection;
}

/**
 * Layout component
 */
const Layout: React.FC<LayoutProps> = ({ children, direction }) => {
  return <Flex direction={direction || 'column'}>{children}</Flex>;
};

/**
 * Exports
 */
export { Layout };
