import Icon from '@ant-design/icons';
import * as React from 'react';
import { Icon as CustomIcon } from 'components';
import { ColorShadesType } from 'theme/colors';
// Custom icons
import { ReactComponent as ArrowLongLeftSvg } from '../../assets/icons/icon-arrow-left.svg';
import { ReactComponent as CheckmarkCheckedSvg } from '.../../assets/icons/icon-checkmark-checked.svg';
import { ReactComponent as GoogleSvg } from '.../../assets/icons/icon-google.svg';
import { ReactComponent as MasterCardSvg } from '.../../assets/icons/icon-mastercard.svg';
import { ReactComponent as RadioCheckedSvg } from '.../../assets/icons/icon-radio-checked.svg';
import { ReactComponent as RadioUncheckedSvg } from '.../../assets/icons/icon-radio-unchecked.svg';
import { ReactComponent as MenuSvg } from '.../../assets/icons/icon-menu.svg';
import { ReactComponent as VisaCardSvg } from '.../../assets/icons/icon-visa.svg';
/**
 * Types
 */
export type VibrantIconTypes =
  | 'arrow-long-left'
  | 'checkmark-checked'
  | 'google'
  | 'master-card'
  | 'menu'
  | 'radio-checked'
  | 'radio-unchecked'
  | 'visa';
export type CustomIconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
>;
export type VibrantIconProps = {
  color?: ColorShadesType;
  icon: VibrantIconTypes;
  size?: number;
};

/**
 * CONST
 */
const ICON_MAPPER: { [key in VibrantIconTypes]: any } = {
  'arrow-long-left': <Icon component={ArrowLongLeftSvg} />,
  'checkmark-checked': <Icon component={CheckmarkCheckedSvg} />,
  google: <Icon component={GoogleSvg} />,
  'master-card': <Icon component={MasterCardSvg} />,
  menu: <Icon component={MenuSvg} />,
  'radio-checked': <Icon component={RadioCheckedSvg} />,
  'radio-unchecked': <Icon component={RadioUncheckedSvg} />,
  visa: <Icon component={VisaCardSvg} />,
};

const VibrantIcon: React.FC<VibrantIconProps> = ({ icon, color, size }) => (
  <CustomIcon icon={ICON_MAPPER[icon]} color={color} size={size} />
);

export { VibrantIcon };
