/**
 * Imports
 */
import * as React from 'react';
import { LogoIcon } from './LogoSvg';

/**
 * Types
 */
type LogoProps = {
  size?: number;
  single?: boolean;
};

/**
 * Icon component
 */
const Logo: React.FC<LogoProps> = ({ size, single }) => {
  return (
    <LogoIcon
      single={single}
      style={{ height: size ? size : '32px', width: single ? size : 'auto' }}
    />
  );
};

/**
 * Exports
 */
export { Logo };
