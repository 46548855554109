/**
 * Imports
 */
import * as React from 'react';
import baseStyled, {
  ThemedStyledInterface,
  ThemeProvider as BaseThemeProvider,
  createGlobalStyle,
} from 'styled-components';

import { VibrantColors } from './colors';
import { Transitions } from './transitions';

/**
 * GloblaStyle
 */
const GlobalStyle = createGlobalStyle`
  body,
  html {
    font-family: 'BasierRegular', apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    font-style: normal;
    font-weight: normal;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%
  }
  #root {
  height: 100%
}
  span {
    display: block;
  }
`;

/**
 * Theme
 */
const theme = {
  Vibrant: {
    Colors: VibrantColors,
    Transitions,
  },
};

/**
 * Types
 */
export type VibrantTheme = typeof theme;

/**
 * `styled` object for themed styled-components
 */
const styled: ThemedStyledInterface<VibrantTheme> = baseStyled;
const ThemeProvider: React.FC = ({ children }) => (
  <>
    <GlobalStyle />
    <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
  </>
);

/**
 * Exports
 */
export { theme, styled, ThemeProvider, Transitions, VibrantColors };
