/**
 * Imports
 */
import * as React from 'react';
import { Input as AntdInput, InputProps } from 'antd';
import { styled } from 'theme';

/**
 * Styling
 */
const StyledInput = styled(AntdInput)<InputProps & { ssn?: boolean }>`
  & .ant-form-item-required {
    display: none;
  }
`;

/**
 * Types
 */
export type CustomInputProps = InputProps & {
  ssn?: boolean;
};

/**
 * Input component
 */
const Input: React.FC<CustomInputProps> = ({ ssn, ...props }) => {
  return <StyledInput {...props} ssn={ssn} />;
};

/**
 * Exports
 */
export { Input };
