///////////////////////////////////////////////////////////////////////////////////
// Vibrant Colors
///////////////////////////////////////////////////////////////////////////////////
export type ColorShadesType =
  | 'Black000'
  | 'Blue100'
  | 'Blue300'
  | 'Blue500'
  | 'Blue700'
  | 'Blue900'
  | 'Green100'
  | 'Green300'
  | 'Green500'
  | 'Green700'
  | 'Green900'
  | 'Grey100'
  | 'Grey200'
  | 'Grey300'
  | 'Grey500'
  | 'Grey700'
  | 'Grey800'
  | 'Grey900'
  | 'Orange100'
  | 'Orange300'
  | 'Orange500'
  | 'Orange700'
  | 'Orange900'
  | 'Red100'
  | 'Red300'
  | 'Red500'
  | 'Red700'
  | 'Red900'
  | 'White000'
  | 'Yellow100'
  | 'Yellow300'
  | 'Yellow500'
  | 'Yellow700'
  | 'Yellow900';
/**
 * All colors and their variations
 */
const ColorShades = {
  /**
   * Black/White
   */
  Black000: '#000000',
  White000: '#FFFFFF',

  /**
   * Grey
   */
  Grey100: '#EEEEEE', // Grey BG
  Grey200: '#E0E0E0', // Border Primary
  Grey300: '#C8C8C8', // Text placeholder / Disabled button
  Grey500: '#8D8D8D', // UI Text
  Grey700: '#656565', //
  Grey800: '#393939', // Paragraph
  Grey900: '#141414', // Heading / Labels
  /**
   * Blue / Office
   */
  Blue100: '#DFF3F2',
  Blue300: '#BDEBEB',
  Blue500: '#5CB5C1',
  Blue700: '#275F71',
  Blue900: '#0C212B',
  /**
   * Green / Bank
   */
  Green100: '#CFF6DF',
  Green300: '#7BEAB4',
  Green500: '#27B68B',
  Green700: '#0E6C5B',
  Green900: '#06302D',
  /**
   * Orange / Honey
   */
  Orange100: '#FFEBDF',
  Orange300: '#FFCAAC',
  Orange500: '#FF8A48',
  Orange700: '#B45722',
  Orange900: '#49210B',
  /**
   * Red / Rose
   */
  Red100: '#FFDFDC',
  Red300: '#FFAEA7',
  Red500: '#FF6E61',
  Red700: '#B7291C',
  Red900: '#63130C',
  /**
   * Yellow / Coin
   */
  Yellow100: '#FFF3DD',
  Yellow300: '#FFD88E',
  Yellow500: '#FFC045',
  Yellow700: '#AF7A14',
  Yellow900: '#513500',
};

/**
 * Main variation of colors
 */
const BaseColors = {
  Brand: ColorShades.Orange500,
  Black: ColorShades.Black000,
  White: ColorShades.White000,
  Blue: ColorShades.Blue500,
  Green: ColorShades.Green500,
  Orange: ColorShades.Orange500,
  Red: ColorShades.Red500,
  Yellow: ColorShades.Yellow500,
};

/**
 * Backgrounds
 */
const BackgroundColors = {
  Brand: ColorShades.Orange500,
  Primary: ColorShades.Grey100,
  Secondary: ColorShades.Grey200,
  Black: ColorShades.Black000,
  White: ColorShades.White000,
  Disabled: ColorShades.Grey300,
  OfficeLight: ColorShades.Blue100,
};

/**
 * Border & Strokes
 */
const BorderColors = {
  BorderPrimary: ColorShades.Grey200,
  BorderSecondary: ColorShades.Grey300,
  BorderBrand: ColorShades.Orange500,
  InputBorder: ColorShades.Grey200,
};

/**
 * Button colors
 */
const ButtonColors = {
  Primary: ColorShades.Orange500,
  PrimaryHover: ColorShades.Orange700,
  PrimaryActive: ColorShades.Orange700,
  PrimaryColor: ColorShades.Orange500,
  Secondary: ColorShades.Green900,
  SecondaryHover: ColorShades.Blue900,
  SecondaryActive: ColorShades.Blue900,
  SecondaryColor: ColorShades.White000,
  Link: ColorShades.Grey900,
  LinkHover: ColorShades.Grey800,
  LinkColor: ColorShades.Grey800,
  Disabled: ColorShades.Grey300,
};

/**
 * Elevation (drop shadow)
 */
// const ElevationColors = {
//   ElevationPrimary: ColorShades.BlackOpacity008,
// };

/**
 * Feedback colors (ex. system, error, and success messages,)
 */
const FeedbackColors = {
  Danger: ColorShades.Red300,
  DangerHover: ColorShades.Red500,
  Info: ColorShades.Blue100,
  InfoHover: ColorShades.Blue300,
  Success: ColorShades.Green100,
  SuccessHover: ColorShades.Green300,
  Warning: ColorShades.Orange100,
  WarningHover: ColorShades.Orange300,
};
/**
 * Translucent (colors with opacity)
 */
// const TranslucentColors = {
//   Translucent20: 'rgba(250, 250, 250, 0.2)',
//   Translucent40: 'rgba(250, 250, 250, 0.4)',
//   Translucent60: 'rgba(38, 38, 38, 0.2)',
//   Translucent80: 'rgba(38, 38, 38, 0.5)',
// };

/**
 * Typography
 */
const TextColors = {
  // -------- Brand -----------
  Brand: ColorShades.Orange500,
  UI: ColorShades.Grey500,
  // Feedback
  Danger: ColorShades.Red900,
  Info: ColorShades.Blue700,
  Success: ColorShades.Green700,
  Warning: ColorShades.Orange700,
  // Text elements
  Heading: ColorShades.Grey900,
  Input: ColorShades.Grey900,
  Label: ColorShades.Grey900,
  Paragraph: ColorShades.Grey800,
  Placeholder: ColorShades.Grey300,
  // UI & States
  Disabled: ColorShades.Grey100,
  Icon: ColorShades.Grey900,
  Link: ColorShades.Grey900,
  LinkHover: ColorShades.Grey700,
  // Basic
  Black: ColorShades.Black000,
  White: ColorShades.White000,
};

/**
 * Vibrant Theme Colors
 * One Const to rule them all,
 * Main export of theme colors to be used
 */
const VibrantColors = {
  // Color variants
  Base: BaseColors,
  Shades: ColorShades,
  // In-use
  Background: BackgroundColors,
  Border: BorderColors,
  Button: ButtonColors,
  // Elevation: ElevationColors,
  Feedback: FeedbackColors,
  // Opacity: TranslucentColors,
  Text: TextColors,
};

export { VibrantColors };
